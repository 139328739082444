:root {
  --primary: #003060;
  --secondary: #90c2e7;
  --tertiary: #ced3dc;
  --success: #0fb60b;
  --warning: #f77f00;
  --danger: #e21c1c;
}

.hero-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  object-fit: contain;
  background-color: #fff;
  margin-top: 100px;
}

h1 {
  color: var(--primary);
}

p {
  margin-top: 20px;
  color: var(--primary);
}

.hero-btns {
  margin-top: 32px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
