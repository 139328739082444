.hero-gallery .carousel-inner {
  height: 100%;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-size: 60%;
  background-color: var(--primary);
  padding: 20px;
  border-radius: 1000px;
}

.image-indicators {
  cursor: pointer;
  width: min(10vw, 50px);
}

.image-indicators img {
  object-fit: contain;
  object-position: center;
  width: 100%;
  aspect-ratio: 1 / 1;
  transition: "all 0.3s ease-in-out";
}

@media screen and (min-width: 992px) {
  .hero-features .image-indicators {
    width: min(6vw, 100px);
  }
}
