:root {
    --primary: #003060;
    --secondary: #90C2E7;
    --tertiary: #CED3DC;
    --success: #0FB60B;
    --warning: #F77F00;
    --danger: #E21C1C;
}
  
  .btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
  }
  
  .btn--primary {
    background-color: var(--primary);
    color: #fff;
    border-radius: 5px;
    border: 1px solid var(--primary);
    font-family: 'Roboto';
  }

  .btn--secondary {
    background-color: var(--secondary);
    color: #fff;
    border-radius: 5px;
    border: 1px solid var(--secondary);
    font-family: 'Roboto';
  }

  .btn--success {
    background-color: var(--success);
    color: #fff;
    border-radius: 5px;
    border: 1px solid var(--success);
    font-family: 'Roboto';
  }

  .btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid #fff;
    border-radius: 5px;
    transition: all 0.3s ease-out;
  }
  
  .btn--medium {
    padding: 8px 20px;
    font-size: 18px;
  }
  
  .btn--large {
    padding: 12px 26px;
    font-size: 20px;
  }

  .btn--large:hover,
  .btn--medium:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: var(--primary);
    transition: 250ms;
    border: 1px solid var(--primary);
  }