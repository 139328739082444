:root {
  --primary: #003060;
  --secondary: #90c2e7;
  --tertiary: #ced3dc;
  --success: #0fb60b;
  --warning: #f77f00;
  --danger: #e21c1c;
}

h3 {
  color: var(--primary);
}

.success {
  color: var(--success);
}

.report-list {
  background-color: var(--primary);
  margin-top: 100px;
  padding-left: 8px;
  padding-right: 8px;
}

.report-list-title {
  font-weight: 300;
  color: #fff;
  text-align: center;
  line-height: 1.4;
}

.hit-pricing-title {
  color: var(--primary);
  margin-bottom: 20px;
  padding-top: 10px;
  font-weight: bold;
}

.hit-pricing-description {
  color: var(--primary);
}

.pricing-card {
  border: 1px solid var(--primary);
}

.footer-home-inspectors-toolbox {
  font-size: 34px;
  font-weight: bold;
  color: #fff;
}

.hit-link {
  text-decoration: none;
  cursor: pointer;
}

.hit-link--social {
  width: 40px;
}

.hit-link:hover {
  text-decoration: underline;
  color: #fff;
}
