:root {
  --primary: #003060;
  --secondary: #90c2e7;
  --tertiary: #ced3dc;
  --success: #0fb60b;
  --success-light: #beecbe;
  --warning: #f77f00;
  --danger: #e21c1c;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto";
}

p {
  font-size: 20px;
  font-weight: 300;
}

.bold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.text-white {
  color: #fff;
}

.text-center {
  text-align: center;
}

.text-small {
  font-size: 16px;
}

.text-medium {
  font-size: 18px;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.hit-container {
  padding: 50px 0px;
}

.hit-container-background {
  background-color: var(--primary);
}

.primary {
  color: var(--primary);
}

.success {
  color: var(--success);
}

.bg-success {
  background-color: var(--success) !important;
}

.terms-and-conditions-section {
  text-align: justify;
  padding: 50px 10px;
}

.hit-h5 {
  color: var(--secondary);
  font-size: 24px;
  text-align: center;
}

.justify {
  text-align: justify;
}
