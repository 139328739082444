.feature-item__name {
  font-size: 1.3rem;
  line-height: 1em;
}

.feature-item__description {
  font-size: 1rem;
  line-height: 1em;
}

/* .hero-features .carousel-control-prev,
.hero-features .carousel-control-next,
.carousel-indicators {
  display: none;
}

.hero-features .carousel-item img {
  aspect-ratio: 3/2;
}

.image-indicators {
  cursor: pointer;
  width: min(10vw, 50px);
}

.image-indicators img {
  object-fit: contain;
  object-position: center;
  width: 100%;
  aspect-ratio: 1 / 1;
  transition: "all 0.3s ease-in-out";
}

.feature-list {
  display: flex;
  overflow: scroll;
  gap: 15px;

  scroll-behavior: smooth;

  -ms-overflow-style: none; 
  scrollbar-width: none;
}

.feature-list::-webkit-scrollbar {
  display: none;
}

.feature-item {
  background: white;
  aspect-ratio: 1/1;
  line-height: 1em;

  min-width: 150px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10%;
}

.feature-item:hover {
  background-color: var(--secondary);
}

.feature-item--selected {
  background-color: var(--primary);
}

.feature-item--selected:hover {
  background-color: var(--secondary);
}

.feature-item--selected p {
  color: white;
}

.feature-item img {
  width: 50%;
}

.feature-item p {
  font-weight: 400;
}

@media screen and (min-width: 992px) {
  .feature-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 40px;
  }

  .hero-features .image-indicators {
    width: min(6vw, 100px);
  }
}
 */
